<script setup lang="ts">
// vue
import { computed, ref } from 'vue'

// types
import type { Nullable, TutorPackageMatch } from '@revolutionprep/types'

// utilities
import { format } from 'date-fns-tz'

/**
 * types
 * ==================================================================
 */
interface MatchTableItem extends TutorPackageMatch {
  actions: string[]
}

/**
 * props
 * ==================================================================
 */
interface Props {
  matches: TutorPackageMatch[]
  tutorMatchUrl?: Nullable<string>
}
const props = withDefaults(defineProps<Props>(), {
  tutorMatchUrl: null
})

/**
 * state
 * ==================================================================
 */
const expanded = ref([])

/**
 * computed
 * ==================================================================
 */
const columns = computed(() => {
  return [
    {
      name: 'Type',
      property: 'label'
    },
    {
      name: 'Kind',
      property: 'kind'
    },
    {
      name: 'Notes',
      property: 'notes'
    },
    {
      name: 'Created',
      property: 'createdAt'
    },
    {
      name: 'Actions',
      property: 'actions'
    }
  ]
})

const headers = computed(() => {
  return columns.value.map((column) => {
    const header = {
      key: column.property,
      title: column.name,
      sortable: false,
      value: column.property,
      width: ''
    }
    if (column.name === 'Kind') {
      header.width = '33%'
    }
    if (column.name === 'Notes') {
      header.key = 'data-table-expand'
    }
    return header
  })
})

const matchItems = computed(() => {
  return props.matches.map((match) => {
    return {
      ...match,
      createdAt: format(new Date(match.createdAt), 'M/dd/yyyy'),
      actions: match.state !== 'completed' ? ['Edit'] : []
    }
  })
})

/**
 * methods
 * ==================================================================
 */
function actionIcon (action: string) {
  if (action === 'Edit') {
    return 'mdi-pencil-outline'
  } else {
    return ''
  }
}

function getReason (item: MatchTableItem) {
  if (item.reason) {
    let reason = item.reason
    if (item.category) {
      reason += ` – ${item.category}`
    }
    if (item.detail) {
      reason += ` – ${item.detail}`
    }
    return reason
  } else {
    return ''
  }
}

function showAction (action: string) {
  if (action === 'Edit' && props.tutorMatchUrl) {
    return true
  } else {
    return false
  }
}
</script>

<template>
  <client-only>
    <v-data-table
      v-model:expanded="expanded"
      class="elevation-1"
      density="compact"
      :headers="headers"
      :items="matchItems"
      :items-per-page="5"
      show-expand
    >
      <template #item.label="{ item }">
        <div class="font-weight-bold">
          {{ item.label }}
        </div>
      </template>
      <template #item.kind="{ item }">
        <div :class="item.reason && 'pt-1'">
          {{ item.kind }}
        </div>
        <div
          v-if="item.reason"
          class="text-grey caption pb-1"
        >
          {{ getReason(item) }}
        </div>
      </template>
      <template #item.data-table-expand="{ isExpanded, toggleExpand, item, internalItem }">
        <v-tooltip
          v-if="item.notes"
          location="bottom"
        >
          <template #activator="{ props: tooltipProps }">
            <v-btn
              v-bind="tooltipProps"
              icon
              size="x-small"
              variant="flat"
              @click="toggleExpand(internalItem)"
            >
              <v-icon color="primary">
                mdi-note-multiple-outline
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ isExpanded(internalItem) ? 'Hide notes' : 'View notes' }}
          </span>
        </v-tooltip>
        <div
          v-else
          class="text-grey"
        >
          N/A
        </div>
      </template>
      <template #expanded-row="{ columns: expandedItemHeaders, item }">
        <tr>
          <td
            :colspan="expandedItemHeaders.length"
            class="formatted-text py-3"
          >
            {{ item.notes }}
          </td>
        </tr>
      </template>
      <template #item.actions="{ item }">
        <div
          v-for="action in item.actions"
          :key="`tutor-match-${item.id}-action-${action}`"
        >
          <v-tooltip
            v-if="showAction(action)"
            location="bottom"
          >
            <template #activator="{ props: tooltipProps }">
              <v-btn
                v-if="tutorMatchUrl"
                v-bind="tooltipProps"
                class="me-2"
                :href="tutorMatchUrl"
                icon
                size="x-small"
                target="_blank"
                variant="flat"
              >
                <v-icon color="primary">
                  {{ actionIcon(action) }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ action }}
            </span>
          </v-tooltip>
        </div>
        <div
          v-if="!item.actions.length"
          class="text-grey"
        >
          N/A
        </div>
      </template>
      <template
        v-if="matchItems.length <= 5"
        #bottom
      />
    </v-data-table>
  </client-only>
</template>

<style scoped>
.formatted-text {
  padding-top: 0;
  white-space: pre-line;
  font-size: 14px;
}
</style>
