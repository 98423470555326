<script lang="ts" setup>
import Dialog from './Dialog.vue'

/**
 * props
 * ==================================================================
 */
interface Props {
  firstSessionFocus: string
  show?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    show: false
  }
)

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits(['toggle-dialog'])
</script>

<template>
  <Dialog
    :show="props.show"
    close-icon-class="position-absolute top-0 right-0 pe-3 pt-3"
    @toggle-dialog="emit('toggle-dialog', !props.show)"
  >
    <template #title>
      <v-icon>
        custom:UserSession
      </v-icon>
      <span class="dialog-title ps-2">
        First Session Focus
      </span>
    </template>
    <template #content>
      <r-input-text-area
        class="text-area-first-session-focus mb-6 mt-4"
        label="First Session Focus"
        :value="props.firstSessionFocus"
        :readonly="true"
        :rows="15"
      />
      <div class="w-100 text-right">
        <v-btn @click="emit('toggle-dialog', false)">
          close
        </v-btn>
      </div>
    </template>
  </Dialog>
</template>

<style>
.dialog-title {
  font-family: 'Roboto';
  font-weight: 600;
}

.text-area-first-session-focus {
  background-color: rgb(var(--v-theme-backgroundlightgrey2));
}
</style>
