<script setup lang="ts">
// vue
import { computed } from 'vue'

// types
import type { Nullable, Subject } from '@revolutionprep/types'

/**
 * props
 * ==================================================================
 */
interface Props {
  duration?: Nullable<number>
  frequency?: Nullable<number>
  subject: Subject
  subjectLabel: string
}
const props = withDefaults(defineProps<Props>(), {
  duration: null,
  frequency: null
})

/**
 * computed
 * ==================================================================
 */
const subjectName = computed(() => {
  return props.subject?.name || null
})
</script>

<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
      sm="4"
    >
      <div class="text-overline">
        {{ subjectLabel }}
      </div>
      <div class="text-body-1 font-weight-bold">
        {{ subjectName }}
      </div>
    </v-col>
    <v-col
      v-if="frequency"
      cols="12"
      md="3"
      sm="4"
    >
      <div class="text-overline">
        Sessions Per Week
      </div>
      <div class="text-body-1 font-weight-bold">
        {{ frequency }}
      </div>
    </v-col>
    <v-col
      v-if="duration"
      cols="12"
      md="3"
      sm="4"
    >
      <div class="text-overline">
        Session Length
      </div>
      <div class="text-body-1 font-weight-bold">
        {{ duration }} minutes
      </div>
    </v-col>
  </v-row>
</template>
