// utilities
import { toFormattedDate } from '@revolutionprep/utils'

export function useFormatDate () {
  function doFormatDate (
    date: Date,
    desiredFormat: string,
    isRelative = false
  ) {
    if (!date || !desiredFormat) {
      return 'N/A'
    }
    return toFormattedDate(date, desiredFormat, isRelative)
  }

  return {
    doFormatDate
  }
}
