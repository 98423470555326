<script setup lang="ts">
// types
import type { OrbitApiMeta, TutorPackageTranscript } from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// composables
import { usePage } from '@/composables/page'

// stores
import { useGlobalStore } from '@/store/global'

/**
 * page metadata
 * ==================================================================
 */
definePageMeta({
  layout: 'blank',
  name: 'StudentProfilePage',
  middleware: ['validate-route-roles']
})

/**
 * route
 * ==================================================================
 */
const route = useRoute()

/**
 * fetch repositories
 * ==================================================================
 */
const { $tutorPackageTranscripts } = useNuxtApp()

/**
 * stores
 * ==================================================================
 */
const examTranscriptSummaryStore = useExamTranscriptSummaryStore()

const globalStore = useGlobalStore()
const { pageTitle } = storeToRefs(globalStore)

const gradeLevelsStore = useGradeLevelsStore()
const { gradeLevels } = storeToRefs(gradeLevelsStore)

const propertiesStore = usePropertiesStore()

const studentStore = useStudentStore()
const { student } = storeToRefs(studentStore)

const studyAreaStore = useStudyAreaStore()
const { sortedStudyAreas, studyAreas } = storeToRefs(studyAreaStore)

const subjectStore = useSubjectStore()
const { subjects } = storeToRefs(subjectStore)

const tutorPackageTranscriptStore = useTutorPackageTranscriptStore()
const { tutorPackageTranscripts } = storeToRefs(tutorPackageTranscriptStore)

/**
 * state
 * ==================================================================
 */
const isLoading = ref(true)
const showSkeleton = ref(true)
const pageNumber = ref(1)
const title = ref('Student Profile')
const totalPages = ref(1)

/**
 * composables
 * ==================================================================
 */
const { doHandleError } = useErrorHandler()

usePage(title)

/**
 * form validation errors
 * ==================================================================
 */
const { errors } = useForm()

/**
 * methods
 * ==================================================================
 */
async function fetchTutorPackageTranscripts (pageNumber: number) {
  const { tutorPackageTranscripts: fetchedTutorPackageTranscripts, meta } =
  await $tutorPackageTranscripts.index<{
      ['tutorPackageTranscripts']: TutorPackageTranscript[],
      meta: OrbitApiMeta
    }>({
      params: {
        studentId: route.params.studentId,
        adjustment: false,
        status: 'won',
        orderBy: 'created_at',
        orderSort: 'desc',
        page: pageNumber,
        per: 5,
        include: 'active_match,enrollment,matches,subject,additional_subject,affiliate_reservation.*'
      }
    })
  tutorPackageTranscripts.value = fetchedTutorPackageTranscripts || []
  if (meta) {
    totalPages.value = meta.totalPages
  }
}

async function updatePageNumber (_pageNumber: number) {
  if (_pageNumber > totalPages.value) {
    return
  }
  showSkeleton.value = true
  pageNumber.value = _pageNumber
  try {
    await fetchTutorPackageTranscripts(pageNumber.value)
  } catch (errorResponse) {
    doHandleError(errorResponse as Error)
  } finally {
    showSkeleton.value = false
  }
}

/**
 * lifecycle hooks
 * ==================================================================
 */
onMounted(() => {
  isLoading.value = false
  showSkeleton.value = false
})

/**
 * page setup
 * ==================================================================
 */
pageTitle.value = title.value

/**
 * data fetching
 * ==================================================================
 */
const { error } = await useLazyAsyncData('student-profile-page',
  async () => {
    try {
      await studentStore.show(
        Number(route.params.studentId),
        {
          params: {
            include: 'school,study_areas.*,properties'
          }
        }
      )

      studyAreas.value = student.value?.studyAreas || []

      await Promise.all([
        fetchTutorPackageTranscripts(pageNumber.value),
        gradeLevelsStore.index({
          params: {
            withGradYear: true
          }
        }),
        studyAreaStore.index({
          params: {
            studentId: student.value?.id,
            archived: true,
            include: 'last_snapshot,snapshots,subject'
          }
        }),
        examTranscriptSummaryStore.index({
          headers: {
            Authorization: student.value?.examsApiToken || ''
          }
        }),
        subjectStore.index({
          params: {
            grade: student.value?.grade,
            excludeCategoryId: [7]
          }
        }),
        propertiesStore.index()
      ])
    } catch (error) {
      doHandleError(error as FetchError)
    }
  }
)
</script>

<template>
  <div>
    <v-container>
      <v-row v-if="error">
        <v-col>
          <r-error-display :error="error" />
        </v-col>
      </v-row>
      <v-row v-else-if="isLoading">
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader type="heading" />
            </v-col>
            <v-col cols="12">
              <v-skeleton-loader type="list-item-two-line" />
            </v-col>
            <v-col cols="12">
              <v-skeleton-loader
                type="heading, divider, list-item-three-line, list-item-two-line, actions"
              />
            </v-col>
            <v-col cols="12">
              <v-skeleton-loader
                type="heading, divider, list-item-three-line, list-item-two-line, actions"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <header class="my-6">
            <div class="text-h4 font-weight-bold mb-3">
              Student Profile
            </div>
          </header>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader
            v-if="showSkeleton"
            type="article"
          />
          <v-expansion-panels v-else-if="tutorPackageTranscripts.length">
            <r-panel-tutor-package-transcript
              v-for="tutorPackageTranscript of tutorPackageTranscripts"
              :key="String(tutorPackageTranscript.id)"
              :tutor-package-transcript="tutorPackageTranscript"
            />
          </v-expansion-panels>
          <v-pagination
            v-if="totalPages > 1"
            v-model="pageNumber"
            class="pt-2"
            color="secondary"
            :length="totalPages"
            @update:model-value="updatePageNumber"
          />
        </v-col>

        <v-col cols="12">
          <r-card-validation-errors :validation-errors="errors" />
        </v-col>

        <v-col cols="12">
          <lazy-r-card-edit-student
            v-if="student"
            :grade-levels="gradeLevels"
            :student="student"
          />
          <lazy-r-card-edit-notes
            v-if="student"
            :student="student"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <lazy-r-card-edit-academics
            v-if="student"
            :student="student"
            :study-areas-data="sortedStudyAreas"
            :subjects-data="subjects"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <lazy-r-card-edit-test-prep
            v-if="student"
            :student="student"
            :study-areas="sortedStudyAreas"
            :subjects="subjects"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.sticky-sidebar {
  position: fixed;
  margin-right: 14px;
  width: 26vw;
}

@media (max-width: 960px) {
  .sticky-sidebar {
    width: 31vw;
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter {
  transform: translateY(50px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}
</style>
