import { format } from 'date-fns-tz'
import { add, formatRelative, isBefore } from 'date-fns'
import capitalizeFirst from './capitalizeFirst'

/**
 * Formats a date to a readable format. when isRelative is true
 * it will represent the date in words relative to today's date.
 *
 * @export
 * @param {Date} date
 * @param {string} desiredFormat
 * @param {boolean} [isRelative=false]
 * @returns {string}
 */

export default function (date: Date, desiredFormat: string, isRelative = false): string {
  if (isRelative) {
    const todayDate = new Date()
    const nextWeekStartDate = add(todayDate, { days: 6 })
    return isBefore(date, nextWeekStartDate)
      ? capitalizeFirst(formatRelative(date, todayDate))
      : format(date, desiredFormat)
  }
  return format(date, desiredFormat)
}
